.sll-page-wrapper {

  background-color: rgb(241, 240, 238); //Ljusgrå
  > div > .sv-layout:nth-child(odd) {
    background-color: rgb(225, 221, 218); //Mörkgrå
  }

  /* Första raden har en text-modul som ska ha padding, värsta hacket */
  > .pagecontent > div:nth-child(2) {
      > .sv-grid-main > .sv-row:first-child {
        .sv-text-portlet-content {
          padding:20px;
          h2 {
              margin:0;
          }
        }
      }
  }
}
