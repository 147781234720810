.sll-productinfo {
    ul {
        list-style: none;
        margin:0;
        padding: 0;
        li {
            padding-bottom: 20px;
        }
    }
    &--indication {
        font-weight:bold;
    }
}
