/* file: menu.less */
.sll-menu-wrapper {
  cursor: pointer;
  z-index: 1000;
  nav {
    margin-top:-11px;
    width:350px;
    @media @sm  {
      margin-top: -11px;
    }
  }

  @media @sm {
    right: -10px !important;
  }
}
/* Menyn */
.sll-menu {
  border: solid 1px rgb(171, 162, 155);
  border-top: none;
  @media @sm {
    width: 100vw !important;
  }
  &-wrapper {
    display: none;
    position: absolute;
    margin-top: 79px;
    right:0;
  }

  /* Knappen i listan */
  &__button {
    padding-right: 11px;
    margin-top: 8px;
    float: right;
    height: 25px;
    background-color: transparent;
    border: transparent;
    border-left: solid 1px black !important;
    padding-left: 10px;
    outline: none;
    cursor: pointer;

    &:focus {
      outline-width: 1px;
      outline-style: auto;
      outline-color: Highlight;
      outline-color: -webkit-focus-ring-color;
    }
    .fa {
      font-size: 16px;
    }

    .fa-plusMeny {
      display: block;
      &::before {
        font-family: "courier";
        content: '+';
        font-weight: 900;
        font-size: 27px;
      }
    }


    .fa-minusMeny {
      display: block;
      &::before {
        font-family: "courier";
        content: '-';
        font-weight: 900;
        font-size: 27px;
      }
    }
  }

  &__item {
    cursor: pointer;
    /* Ett snäpp ner i listan */
    &[data-menu-level="2"] {
      background-color: rgb(255, 233, 129) !important;
      border-top: solid 1px black !important;
      a {
        margin-left: 35px;
      }
      &:hover {
        background-color:  rgb(251, 228, 104) !important;
      }
    }

    /* Två snäpp ner i listan */
    &[data-menu-level="3"] {
      background-color: rgb(255, 246, 203) !important;
      border-top: solid 1px black !important;
      a {
        margin-left: 55px;
      }
      &:hover {
        background-color:  rgb(252, 241, 175) !important;
      }
    }

    /* Tre snäpp ner i listan */
    &[data-menu-level="4"] {
      background-color: rgb(255, 246, 203) !important;
      border-top: solid 1px black !important;
      a {
        margin-left: 75px;
      }
      &:hover {
        background-color:  rgb(252, 241, 175) !important;
      }
    }

    * {
      color:black;
    }

    background-color: rgb(255, 216, 25);
    border-top: solid 1px black;
    line-height: 40px;

    span:first-child {
      //width: 88%;
      //display: inline-block;
      padding-left: 10px;
      @media screen and (max-width: 992px)  {
        //width: 90%;
      }
    }

    a { /* Texten i varje item */
      text-decoration: none;
      font-size: 18px;
    }

    &:hover {
      background-color:  rgb(247, 224, 99);
    }
  }

  /* Tar bort prickarna i listan och inskjutningen */
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    > li:first-child {
      border-top: none;
    }
  }

  /* De tre sista items i listan får grå färg */
  li {
    &.sll-menu {
      &__item {
        &:nth-last-of-type(-n+4) {
          background-color: rgb(211, 206, 203);
          &:hover {
            background-color:  rgb(220, 216, 214);
          }
        }
      }
    }
  }


}

/* Knappen som öppnar listan */
.sll-hamburger-button {
  &:focus {
    outline-width: 1px;
    outline-style: auto;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
  }

  width: 4.6em;
  height: 5.1em;
  float: right;
  margin-left: 6px;
  outline: none;
  border: solid 1px #002d5a;
  background-color: #002d5a;
  margin-bottom: -0.95em;
  cursor: pointer;

  .sll-hamburger {
    &-text {
      color: white;
    }
    >span {
      display: block;
      width: 33px;
      height: 3px;
      border-radius: 2px;
      background-color: #fff;
      transition: transform 500ms;
      margin-left: auto;
      margin-right: auto;
    }
    &__middle {
      margin-top: 6px;
      margin-bottom: 6px;
      transition: width 500ms;
    }

    &__bottom {
      margin-top: 6px;
      margin-bottom: 6px;
      transition: width 500ms;
    }
  }

  &--active {
    background-color: rgb(255, 216, 25);
    border-bottom: rgb(255, 216, 25);
    .sll-hamburger {

      > span {
        background-color: black;
      }

      &-text {
        color: black;
      }

      &__middle {
        background-color: black;
      }

      &__bottom {
        background-color: black;
      }
    }

  }
}
