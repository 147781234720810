/* lessfile: responsive.less */

@xs-min: 0;
@sm-min: 480px;
@md-min: 768px;
@lg-min: 992px;
@xl-min: 1200px;
@xs-max: (@sm-min - 1);
@sm-max: (@md-min - 1);
@md-max: (@lg-min - 1);
@lg-max: (@xl-min - 1);
@xs: ~"(max-width: @{xs-max})";
@sm: ~"(max-width: @{sm-max})";
@sm-strict: ~"(min-width: @{sm-min}) and (max-width: @{sm-max})";
@md: ~"(max-width: @{md-max})";
@md-strict: ~"(min-width: @{md-min}) and (max-width: @{md-max})";
@lg: ~"(max-width: @{lg-max})";
@lg-strict: ~"(min-width: @{lg-min}) and (max-width: @{lg-max})";
@xl: ~"(min-width: @{xl-min})";

@grid-width: 1440px;
@grid-min: (@grid-width + 1);
@col-size: 12;
@col-spacing: 34px;
.generate-columns(@n; @selector; @i:1) when (@i =< @n) {
  @col: ~"@{selector}-@{i}";
  @push: ~"@{selector}-push-@{i}";
  @pull: ~"@{selector}-pull-@{i}";
  @{col} {
    flex: 1 0 (@i * 100% / @n);
    max-width: (@i * 100% / @n);
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  @{push} {
    position: relative;
    left: (@i * 100% / @n);
  }
  @{pull} {
    position: relative;
    left: -(@i * 100% / @n);
  }

  .generate-columns(@n, @selector, (@i + 1));
}
.generate-row(@col-class) {
  .sol-row,
  .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -(@col-spacing/2);
    margin-right: -(@col-spacing/2);
    justify-content: flex-start;
    @media @md {
      margin-left: -(@col-spacing/4);
      margin-right: -(@col-spacing/4);
    }
    &.sol-row-reverse {
      flex-direction: row-reverse;
    }
    &.sol-row-center {
      justify-content: center;
    }
    &::before, &::after{
      display: none;
    }
    .generate-columns(@col-size, ~  '*.@{col-class}');
  }
}
//Init grid
.sv-grid-main.sv-fluid-grid,
.sol-grid {
  padding-left: 20px;
  padding-right: 20px;
  max-width: @grid-width;
  margin-left: auto;
  margin-right: auto;
  @media @lg {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media @xl {
    .generate-row(sv-column);
    .generate-row(sol-col-xl);
  }
  @media @lg-strict {
    .generate-row(sv-column);
    .generate-row(sol-col-lg);
  }
  @media @md-strict {
    .generate-row(sol-col-md);
  }
  @media @sm-strict {
    .generate-row(sol-col-sm);
  }
  & *[class*= ~'sol-col-'],
  & *[class*= ~'sv-column-'] {
    flex: 1 0 100%;
    max-width: 100%;
    width: auto;
    float: none !important;
    display: block;
    padding-left: (@col-spacing/2);
    padding-right: (@col-spacing/2);
    margin: 0 !important;
    @media @md {
      padding-left: (@col-spacing/4);
      padding-right: (@col-spacing/4);
    }
  }
}
.sol-show-lg,
.sol-show-md,
.sol-show-sm,
.sol-show-xl,
.sol-show-xs {
  display: none !important;
}
@media @xl {
  .sol-hide-xl {
    display: none !important;
  }
  .sol-show-xl {
    display: inherit !important;
  }
}
@media @lg-strict {
  .sol-hide-lg {
    display: none !important;
  }
  .sol-show-lg {
    display: inherit !important;
  }
}
@media @md-strict {
  .sol-hide-md {
    display: none !important;
  }
  .sol-show-md {
    display: inherit !important;
  }
}
@media @sm-strict {
  .sol-hide-sm {
    display: none !important;
  }
  .sol-show-sm {
    display: inherit !important;
  }
}
@media @xs {
  .sol-hide-xs {
    display: none !important;
  }
  .sol-show-xs {
    display: inherit !important;
  }
}

/* NOTE: ska det ligga här? */
.sv-row {
  > .sv-layout {
    padding-top: 10px;
    &:first-child {
      padding-top:0;
    }
    @media (min-width: @lg-min) {
      padding:0;
    }
  }
}
