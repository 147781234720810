.sll-margin {
    padding-bottom: 0;
    margin-bottom: 0;
    
    h1 {
        margin-top: -7px !important;
    }

    h2 {
        margin-top: 3px !important;
        //font-size: 30pt !important;
    }

    h3 {
        margin-top: 13px !important;
    }

    h4 {
        margin-top: 12px !important;
    }

    .sv-row {
        .sv-text-portlet {
            p {
                @media (min-width: @lg-min) {
                    margin-top: 0.6em !important;
                    padding-left: 17px;
                }
            }
        }
    }

    .sv-layout {
        & + .sv-layout {
            .sv-text-portlet {
                p {
                    /* Desktop-läge */
                    @media (min-width: @lg-min) {
                        text-align: right;
                    }

                    @media @sm {
                    text-align: left !important;
                    }
                }
            }
        }
    }
     
    
}

.sll-abc {

    /* Bokstäver */
    .sv-abc-portlet {
        background-color: white;
        & div + div {
            border-bottom: solid 5px #1daeec;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
            a {
                margin-right: 1.1em;
                text-decoration: none;
                font-weight: bold;
                margin-left: 1em;
                &:first-child {
                    margin-left: 1em;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        } 

        .sol-active {             
            &::before {
                content: '\f0d8 ';
                font-family: FontAwesome;
                margin-top: 0.6em;
                position: absolute;
                margin-left: 0px;
                color: #1daeec;
                font-size: 24px;
            }
        }
    }

    li {
        display: inline-block;
        width: 37%;
        @media (min-width: @lg-min) {
            padding-left: 1em !important;
        }

        @media @md {
            width: 100%;
            padding-left: 1em;
        }
        &:nth-child(3n) {
            @media (min-width: @lg-min) {
                width: 25%;
            }
        }
        a {
            color: black !important;
            font-weight: bold;
        }
    }
}