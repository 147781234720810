.sll-fortbildning-search {
    .sv-row {
        &:first-child {
            background-color: #e1ddda;
            width: 101.2%;
        }

        .sv-text-portlet {
            margin-left: 2em;
        }

        .sv-proxy-portlet + .sv-script-portlet {
            margin-left: 0;
        }
    }

    .sol-news-selector-education {
        margin-left: 3em;
        @media @sm {
            margin-left: 2em;
        }

        @media @sm {
            margin-top: 0;
            margin-left: 0px;
            margin-bottom: 10px;
        }

        select {
            cursor: pointer;
            @media @sm {
                margin-bottom: 10px;
            }
        }

        option {

        }

    }

    .sll-news-list {
        list-style: none;
        padding-left: 0;
        &-item {
            background-color: white;
            padding: 1.5em;
            padding-bottom: 0;
            padding-top: 0.5em;
            display: inline-block;
            margin-right: 0.9em;
            margin-bottom: 1em;
            height: 9em;
            //width: 14.16em;
            width: 19em;
            @media @sm {
                width: 100%;
                height: 100%;
            }
            overflow: hidden;
            &:nth-child(odd) {
                border-top: solid 8px #042e59;
            }
            &:nth-child(even) {
                border-top: solid 8px #e99023;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }

            &__date {
                font-size: 18px;
                font-weight: bold;
            }

            &__header {
                font-size: 22px;
                font-weight: 500;
                line-height: 1;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            &__summary {
                font-style: italic;
                font-size: 16px;
                line-height: 1;
                padding-top: 10px;
                margin-bottom: -5px;
            }

            &__link {
                font-size: 16px;
                &:hover {
                    opacity: 0.7;
                }
                &::before {
                    content: '\f105';
                    font-family: FontAwesome;
                    font-weight: bold;
                    padding-right: 5px;
                } 
            }
        }
    }

    .sll-news-load-more {
        //margin-left: 39vw;
    }

    select {
        height: 40px;
        padding-left: 15px;
        width: 20em;
        @media @sm {
            width: 95%;   
        }
    }

    .MonthDropdown {
        margin-left: 3em;
    }

    .fortbildning-search-button {
        cursor: pointer;
        border-radius: 50%;
        width: 3em;
        height: 3em;
        background: saddlebrown;
        border: none;
        vertical-align: top;
        @media @sm {
            margin-top: 2em;
        }
        .fa-search {
            color: white;
            padding: 0;
            margin-right: 2px;
            margin-bottom: 3px;
        }

        svg {
            margin-top: 4px;

            @media only screen and (device-width : 360px)  and (device-height : 740px) {
                margin-left: 0px !important;
                margin-top: 4px !important;
            }

            @media @sm {
                margin-left: -3px;
                margin-top: 6px;
            }
        }
    }


}


.sll-fortbildning-xml {
    width: 99.4%;
    .listitem {
        background-color: white;
        padding: 1.5em;
        padding-bottom: 0;
        padding-top: 0.5em;
        display: inline-block;
        margin-right: 0.9em;
        margin-bottom: 1em;
        height: 250px;
        width: 32.3%;
        
        @media only screen and (max-width: 1200px) and (min-width: 806px){
            width: 48.5%;
            &:nth-child(2n) {
                margin-right: 0 !important;
            }
        }

        @media only screen and (max-width: 806px) {
            width: 48.1%;
        }



        @media @sm {
            width: 100%;
            height: 100%;
        }

        overflow: hidden;

        &:nth-child(odd) {
            border-top: solid 8px #042e59;
        }

        &:nth-child(even) {
            border-top: solid 8px #e99023;
        }

        &:nth-child(3n) {       
            @media only screen and (max-width: 1200px) and (min-width: 806px) {
                margin-right: 0.9em;
            }

            @media (min-width: 1200px) {
                margin-right: 0;
            }
        }
    }

    #proxyerror {
        margin-left: 2em;
    }

    .sol-Date {
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    .sol-EventName {
        display: inline-block;
        font-size: 22px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .sol-TargetAudience {
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        font-style: italic;
        margin-top: 0;
    }

    .sol-lastDate {
        padding-top: 10px;
        padding-bottom: 10px;
        font-style: italic;
        margin-top: 0;
        padding-bottom: 5px;
    }

    .sol-readmore {
        margin-top: 0;
        a {
            color: #126998 !important;
            text-decoration: none !important;
            font-size: 16px;
            &:hover {
                opacity: 0.7;
            }
            &::before {
                content: '\f105';
                font-family: FontAwesome;
                font-weight: bold;
                padding-right: 5px;
            } 
        }        
    }

    .sol-fortbildning-detail {
        h1 {
            margin-top: 24px;
        }

        table {
            width: 100%;
        }

        .title {
            font-size: 30px;
            padding-left: 0;
        }

        p {
            display: inline-block;
            width: 100%;
        }

        td {
            &:nth-child(odd), .information {
                width: 10em;
                vertical-align: top;
                @media @sm {
                    width: 30%;
                }
            }
        }

        .sol-fortbildning-row {
          display: block;
          padding: 10px;
          overflow: hidden;
          
          span {
            float: right;
            width: 73%;
          }

          &:nth-child(odd) {
            background-color: rgb(216, 216, 216);
          }

          @media @sm {
            display: block;
          }
        }

        .sol-fortbildning-organizer {
            display: block;
            width: auto !important;
            margin-left: 10.2em;
            margin-top: -1.8em;
            padding-bottom: 2em;
            &:first {
                padding-bottom: 0;
            }
            @media @sm {
                margin-left: 0;
                margin-top: 0;
            }
        }

        #sol-fortbildning-startdate, #sol-fortbildning-enddate {
            display: none;
        }        

    }

    .sv-text-portlet {
        margin-bottom: 2em;
        margin-top: 2em;
    }

    label {
      width: 40%;
      padding-right: 30px;
      line-height: normal;

      @media @sm {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    input, select {
        height: 1.8em;
        width: 60%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0 25px 0 20px;

        @media @sm {
          width: 100%;
        }
    }

    select {
      padding: 0 25px 0 5px;
    }

    .sol-fortbildning-signup, .sol-fortbildning-load-more {
        background-color: #002d5a;
        padding: 10px;
        color: white;
        border: none;
        cursor: pointer;
    }

    .sol-fortbildning-signup {
      display: block;
      margin: 20px 0;
    }

    .sol-fortbildning-thankyou {
        border: 1px solid green;
        width: fit-content;
        padding: 10px;
    }

    table {
        width: 100%;
    }
}

#h-Aktuellfortbildning {
    display: none;
}

.sol-fortbildning-table {
  margin-bottom: 20px;
}

.sol-fortbildning-form {
  display: none;
  padding-bottom: 20px;
  border-bottom: 1px solid @gray-base;
}

.sol-fortbildning-form-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 0 25px 0;

  @media @sm {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 20px 0;
  }
}

.sol-fortbildning-top-label {
  display: block;
  position: static !important;
  margin: 0 0 20px 0;
}

.sol-fortbildning-confirm {
  background-color: @primary-color;
  padding: 10px;
  color: white;
  border: none;
  cursor: pointer;
}

.sol-fortbildning-message {
  padding: 10px 0 0 0;
  background-color: transparent; 
  color: red; 
  display: none;
}
