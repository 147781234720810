/* lessfile: typography.less */

body {
  font-family: @text-font;
  font-size: @font-size-base;
  color: @body-text-color;
  font-weight: 300;
  background-color: @body-bg;
  & > div.sv-layout,
  & > div.sv-layout > div {
    overflow-x: hidden;
  }
}

/*p,
p.font-normal,
.font-normal, */ li {
  margin-top: 0;
  margin-bottom: 0;
  font-family: @text-font;
  color: @text-color;
  //font-size: @font-size-normal;
  line-height: 1.6em;
  font-weight: 300;
  a.font-normal,
  a {
    color: @link-color;
    text-decoration: none;;
    &:hover {
      color: @link-color;
    }
  }
}
p.font-small,
.font-small {
  margin-top: 0;
  margin-bottom: 3px;
  font-weight: normal;
  font-style: normal;
  font-size: @font-size-small;
  font-family: @text-font;
}

.font-description,
p.font-description {
  color: @text-color;
  font-family: @text-font;
  font-weight: 400;
  font-size: @font-size-large;
  line-height: 1.5em;
  margin-top: 7px;
  margin-bottom: 25px;
  a.font-description,
  a {
    color: @link-color;
    &:hover {
      color: @link-color;
    }
  }
}

p.font-quote,
.font-quote {
  position: relative;
  font-family: @text-font;
  color: @primary-color;
  font-size: @font-size-large;
  line-height: 1.7em;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

ol,
ul {
  &.sol-bullet-list,
  &.sol-numbered-list {
    margin-bottom: 35px;
    padding-left: 0px;
    list-style: none !important;
    & li {
      position: relative;
      margin-bottom: 12px;
      padding-left: 30px;
      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 3px;
        background-color: @primary-color;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }
  }
}

ol,
ul {
  &.sol-numbered-list {
    & li {
      counter-increment: step-counter;
      &::before {
        content: counter(step-counter);
        color: @white;
        font-size: @font-size-small;
        top: 3px;
        font-weight: bold;
      }
    }
  }
}

/* Nyhetssida */

.sv-font-redaktion-artikel {
  border-bottom: solid 1px black;
  border-top: solid 1px black;
}

.sll-publish-date {
  color: #034ea2;
}


.sv-font-kalla-artikel {
  font-size: 19px;
  line-height: 27px;
  padding-top: 1em;
  a {
    text-decoration: none;
    color: #034ea2;
  }
}

a {
  text-decoration: underline !important;
}
