.sv-custom-module {
  > .sv-portlet {
    width:100%;
    height:100%;
    display:flex;
    .sll-link-button {
      width:100%;
      height:100%;
      display:flex;
      align-items: center;
      padding-left: 20px;
      color:white;
      text-decoration: none;
      min-height: 100px;
      &--blue {
          background-color: #002d5a;
      }
      &--red {
        background-color: #970e34;
      }
      &--orange {
        background-color: #cf7b14;
      }
      &--turquoise {
        background-color: #1daeec;
      }
      &--brown {
        background-color: #695c53;
      }
      &--yellow {
        background-color: #FFB81C;
      }

    }
  }
}
