/** file:text_box.less */

.sol-decoration-text-box {
  background-color: white;
  margin-bottom: 20px;

  .sv-search-result {
    margin-top: 0;
    li {
      &:first-child {
        padding-top: 0;
      }
    }
  }

  .sv-row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sv-font-publiceringsdatum {
    padding-top: 5px;
  }

  &__header {
    h2 {
      margin:0;
      padding: 20px;
      color:white;
      //font-size: 30pt;
      line-height: 34pt;
    }
    border-bottom: 8px solid;
  }
  &--blue &__header {
    background-color: #042e59;
    border-bottom-color: #1daeec;
  }
  &--orange &__header {
    background-color: #cf7b14;
    border-bottom-color: #fed230;
  }
  &--red &__header {
    background-color: #970e34;
    border-bottom-color: #df156f;
  }

  &--gray &__header {
    background-color: #685c53;
    border-bottom-color: #a79d96;
  }

}
