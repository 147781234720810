.sll-subtance-indication {
    table {
        width:100%;
        @media @sm {
            display: block;
            overflow-x: auto;
        }
    }

    thead {
        background: #259ae8;
        color: white;
        th {
            padding:15px;
            text-align:left;
        }
        .tablesorter-header-inner {
            display: inline;
        }
    }
    tbody {
        tr {
            &:nth-child(even) {
                background: rgba(167,157,150,0.35);
            }
            &:last-child {
                white-space: nowrap;
            }
            td {
                padding: 5px 10px;
                @media @sm {
                    padding:5px;
                }
                p {
                    margin:0px;
                }
            }
        }
    }
}
