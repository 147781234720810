/* lessfile: mixins.less */

.clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.placeholder(@rules) {
  &::-webkit-input-placeholder {  
    @rules();
  }
  &:-moz-placeholder {
    @rules();
  }
  &::-moz-placeholder {
    @rules();
  }
  &:-ms-input-placeholder {
    @rules();
  }
}

.transition(@property, @duration: 200ms, @style: ease) {
  -webkit-transition-property: @property;
  transition-property: @property;
  -webkit-transition-duration: @duration;
  transition-duration: @duration;
  -webkit-transition-timing-function: @style;
  transition-timing-function: @style;
}

.no-appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
}

.no-appearance-sc{ 
  .no-appearance; 
  text-shadow: none;
  background-image: none;
  box-shadow: none;
  padding: 0;
  line-height: @line-height-base;
  &:hover{
    background: none;
    border: none;
  }
  &:active{
    background: none;
    box-shadow: none;
  }
}

.no-list-style() { 
  list-style: none;
  padding: 0;
  margin: 0;
}

.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}

.icon(@icon; @size: inherit; @position: after) {
  .fa;
  display: inline-block;
  font-size: @size;
  .position(@position);
  .position(@position) when (@position =before) {
    &::before {
      content: @icon;
    }
  }
  .position(@position) when (@position =after) {
    &::after {
      content: @icon;
    }
  }
  .position(@position) when (@position =none) {
    content: @icon;
  }
}

.no-outline-focus() {
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
  }
}

.outline-focus() {
  &:focus,
  [data-whatinput="keyboard"] &:focus {
    outline: 2px solid @focus-color;
    outline-offset: -2px;
    z-index: 10;
  }
  [data-whatinput="initial"] &:focus,
  [data-whatinput="mouse"] &:focus,
  [data-whatinput="touch"] &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    outline: none;
    z-index: inherit;
  }
}

.remove-glyphicon() {
  background-image: none !important;
  width: auto;
  height: auto;
  vertical-align: middle;
  margin: 0;
  line-height: 1;
}
