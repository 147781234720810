.sll-janusmed {
    background-color: white;
    .sll-banner {
        display: none;
    }

    .deco_square1, .deco_square2, .deco_square3, .deco_square4 {
        display: none;
    }
}

//Kontakt-modal
div.modal.fade.ng-scope.ng-isolate-scope.in {
    margin: auto;
    width: initial;
    background-color: transparent;
    top: 0 !important;
    //overflow-y: hidden !important;

    .modal-dialog {
        width: auto !important;
        top: 0 !important;
        .modal-content {
            .modal-body {
                max-height: initial;
            }

            .fa-times {
                &:before {
                    content: "X";
                }
            }
        }
    }
}