/* lessfile: font.less */

//@import (inline) url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700,900');

@frutiger-path: '@{font-path}/frutiger';

@font-face{
font-family:"FrutigerLTW01-55Roman";
src:url("@{frutiger-path}/af97a512-f55a-4f61-b2ce-659c883930fc.eot?#iefix");
src:url("@{frutiger-path}/af97a512-f55a-4f61-b2ce-659c883930fc.eot?#iefix") format("eot"),url("@{frutiger-path}/3fbbd6b1-cfa7-4ff0-97ea-af1b2c489f15.woff") format("woff"),url("@{frutiger-path}/076311f8-a01b-4389-a5eb-334a62e62d45.ttf") format("truetype"),url("@{frutiger-path}/c59a36ab-4ef8-4ee1-a2f8-48aa79d4f877.svg#c59a36ab-4ef8-4ee1-a2f8-48aa79d4f877") format("svg");
}
@font-face{
//font-family:"FrutigerLTW01-45Light";
font-family: "Frutiger";
font-weight:300;
src:url("@{frutiger-path}/7d3671fc-8c26-459c-98e1-997c4f6c8c93.eot?#iefix");
src:url("@{frutiger-path}/7d3671fc-8c26-459c-98e1-997c4f6c8c93.eot?#iefix") format("eot"),url("@{frutiger-path}/2a004a53-ac5c-43b3-9eeb-9f74ae4c1609.woff") format("woff"),url("@{frutiger-path}/74d53f3b-1683-4d5a-a556-e13f6553cdf0.ttf") format("truetype"),url("@{frutiger-path}/3f5a5b87-e71e-4544-be0c-da4daa132710.svg#3f5a5b87-e71e-4544-be0c-da4daa132710") format("svg");
}
@font-face{
//font-family:"FrutigerLTW01-47LightCn";
font-family: "Frutiger";
font-weight:300;
font-stretch: condensed;
src:url("@{frutiger-path}/e5b1a25e-0d2b-4aec-8672-da21553f205d.eot?#iefix");
src:url("@{frutiger-path}/e5b1a25e-0d2b-4aec-8672-da21553f205d.eot?#iefix") format("eot"),url("@{frutiger-path}/e59898b4-93a8-4df7-885a-7806f9f0604d.woff") format("woff"),url("@{frutiger-path}/85928e7e-2ace-4f1d-be54-b11c333a8d97.ttf") format("truetype"),url("@{frutiger-path}/89390d0d-7a62-493d-9e77-9c162a709520.svg#89390d0d-7a62-493d-9e77-9c162a709520") format("svg");
}
@font-face{
font-family:"FrutigerLTW01-48LightCn";
src:url("@{frutiger-path}/ce25b811-79b7-4f2a-ad26-da9dcd6f1040.eot?#iefix");
src:url("@{frutiger-path}/ce25b811-79b7-4f2a-ad26-da9dcd6f1040.eot?#iefix") format("eot"),url("@{frutiger-path}/a40a90de-6c25-4a07-aef6-fbaa818362ab.woff") format("woff"),url("@{frutiger-path}/f366ec53-c110-4521-bfbd-1a605bfaf6ac.ttf") format("truetype"),url("@{frutiger-path}/4140fb7e-33dc-47c2-ab18-f8e3a59cf351.svg#4140fb7e-33dc-47c2-ab18-f8e3a59cf351") format("svg");
}
@font-face{
//font-family:"FrutigerLTW01-56Italic";
font-family:"Frutiger";
font-weight:normal;
font-style:italic;
src:url("@{frutiger-path}/fcbab1a3-9659-4b39-8479-cc1f50d18f55.eot?#iefix");
src:url("@{frutiger-path}/fcbab1a3-9659-4b39-8479-cc1f50d18f55.eot?#iefix") format("eot"),url("@{frutiger-path}/e2ade8a9-bd48-45e7-95e6-9dc917ba4f74.woff") format("woff"),url("@{frutiger-path}/19fe9614-8825-4e7d-b8c3-230d2561e0e2.ttf") format("truetype"),url("@{frutiger-path}/c2f8e3aa-1c1a-49ee-9ec7-30f61a5e96d3.svg#c2f8e3aa-1c1a-49ee-9ec7-30f61a5e96d3") format("svg");
}
@font-face{
//font-family:"FrutigerLTW01-46LightIt";
font-family:"Frutiger";
font-weight:300;
font-style:italic;
src:url("@{frutiger-path}/072713ba-cb3a-4522-8f3b-b120f8eae10f.eot?#iefix");
src:url("@{frutiger-path}/072713ba-cb3a-4522-8f3b-b120f8eae10f.eot?#iefix") format("eot"),url("@{frutiger-path}/8259f047-a5d2-40a0-a98c-1c24f0316b08.woff") format("woff"),url("@{frutiger-path}/1078a920-3def-4194-ba69-46751fb9af38.ttf") format("truetype"),url("@{frutiger-path}/4c72fb1e-e1b0-4a18-91cc-5dbe2faaf2a3.svg#4c72fb1e-e1b0-4a18-91cc-5dbe2faaf2a3") format("svg");
}
@font-face{
//font-family:"FrutigerLTW01-68BoldCnI";
font-family:"Frutiger";
font-weight:700;
font-style:italic;
src:url("@{frutiger-path}/07eaadd3-cc57-4268-9d65-928ebdef6252.eot?#iefix");
src:url("@{frutiger-path}/07eaadd3-cc57-4268-9d65-928ebdef6252.eot?#iefix") format("eot"),url("@{frutiger-path}/9b22c211-0e4b-49c7-9db2-291f86d13d28.woff") format("woff"),url("@{frutiger-path}/e8d3db5c-44e6-464d-9b01-9b51f3516494.ttf") format("truetype"),url("@{frutiger-path}/c8f7ec2e-7f97-4bf6-b526-ebb38ca7b3c7.svg#c8f7ec2e-7f97-4bf6-b526-ebb38ca7b3c7") format("svg");
}
@font-face{
font-family:"FrutigerLTW01-76BlackIt";
src:url("@{frutiger-path}/e7f1c490-48bc-447b-ad11-6e85dd1429be.eot?#iefix");
src:url("@{frutiger-path}/e7f1c490-48bc-447b-ad11-6e85dd1429be.eot?#iefix") format("eot"),url("@{frutiger-path}/eb21cbbe-83da-4d1d-bf54-e60fa03381e2.woff") format("woff"),url("@{frutiger-path}/0a0bb96a-4e52-4940-9120-1cbfe63deb29.ttf") format("truetype"),url("@{frutiger-path}/6ce161bf-e26e-4c38-b3d9-0abfcbfc2c6d.svg#6ce161bf-e26e-4c38-b3d9-0abfcbfc2c6d") format("svg");
}
@font-face{
//font-family:"FrutigerLTW01-67BoldCn";
font-family:"Frutiger";
font-stretch: condensed;
font-weight:700;
src:url("@{frutiger-path}/a3a15351-8e3c-4c29-82e6-15b106c2181f.eot?#iefix");
src:url("@{frutiger-path}/a3a15351-8e3c-4c29-82e6-15b106c2181f.eot?#iefix") format("eot"),url("@{frutiger-path}/f1f95a55-f0c6-4fb5-8939-b93160b7a751.woff") format("woff"),url("@{frutiger-path}/032fd83c-fe79-47fa-9dda-0a3f03bd0506.ttf") format("truetype"),url("@{frutiger-path}/17f04727-b70e-460f-8b5c-c8cd21771240.svg#17f04727-b70e-460f-8b5c-c8cd21771240") format("svg");
}
@font-face{
font-family:"FrutigerLTW01-87ExtraBk";
src:url("@{frutiger-path}/7ee7e6db-f32b-4199-ae77-73ffbf62e6ec.eot?#iefix");
src:url("@{frutiger-path}/7ee7e6db-f32b-4199-ae77-73ffbf62e6ec.eot?#iefix") format("eot"),url("@{frutiger-path}/6fb42ae0-a24e-4927-930d-47b462560e78.woff") format("woff"),url("@{frutiger-path}/7522a4a8-1787-4cc6-a72a-3c56c39c2abe.ttf") format("truetype"),url("@{frutiger-path}/ba4991ce-c7b2-48ad-b711-8403662ff6d7.svg#ba4991ce-c7b2-48ad-b711-8403662ff6d7") format("svg");
}
@font-face{
//font-family:"FrutigerLTW01-88XBkCnIt";
font-family:"Frutiger";
font-weight:300;
font-stretch: condensed;
font-style:italic;
src:url("@{frutiger-path}/77f678fd-1072-47dd-9625-022444e2e0f9.eot?#iefix");
src:url("@{frutiger-path}/77f678fd-1072-47dd-9625-022444e2e0f9.eot?#iefix") format("eot"),url("@{frutiger-path}/71bdb8cd-f4e8-45e2-a79f-b9c65623efb5.woff") format("woff"),url("@{frutiger-path}/08b2ef93-56e2-48a3-a319-51393412e291.ttf") format("truetype"),url("@{frutiger-path}/df88893a-3709-49cc-a45d-b051349b5974.svg#df88893a-3709-49cc-a45d-b051349b5974") format("svg");
}
@font-face{
font-family:"FrutigerLTW01-95UltraBl";
src:url("@{frutiger-path}/47e272a5-5523-490a-a689-1e4660531849.eot?#iefix");
src:url("@{frutiger-path}/47e272a5-5523-490a-a689-1e4660531849.eot?#iefix") format("eot"),url("@{frutiger-path}/3a6c4a0a-2e4b-472c-a995-1a2e93bd1da2.woff") format("woff"),url("@{frutiger-path}/4a66b185-1957-4863-86a8-db4f99ff626e.ttf") format("truetype"),url("@{frutiger-path}/e8e7f894-9084-43e9-b08a-5dcd346ea067.svg#e8e7f894-9084-43e9-b08a-5dcd346ea067") format("svg");
}
@font-face{
font-family:"Frutiger LT W01 65 Bold";
src:url("@{frutiger-path}/fbd7c0fb-f004-4e83-81d2-1d400413a873.eot?#iefix");
src:url("@{frutiger-path}/fbd7c0fb-f004-4e83-81d2-1d400413a873.eot?#iefix") format("eot"),url("@{frutiger-path}/9aa32a81-1124-4c43-b3db-15bfb1f7aed2.woff") format("woff"),url("@{frutiger-path}/6faffbf4-f8e8-4817-b24b-a390e166be7e.ttf") format("truetype"),url("@{frutiger-path}/fc09de64-de25-425e-90dc-a0cae29b02c4.svg#fc09de64-de25-425e-90dc-a0cae29b02c4") format("svg");
}
@font-face{
font-family:"FrutigerLTW01-66BoldIta";
src:url("@{frutiger-path}/f334dc11-1de8-4ae2-9c2a-a1cfa665263b.eot?#iefix");
src:url("@{frutiger-path}/f334dc11-1de8-4ae2-9c2a-a1cfa665263b.eot?#iefix") format("eot"),url("@{frutiger-path}/f74530f5-a39c-47e3-97d9-6821fc4df8c0.woff") format("woff"),url("@{frutiger-path}/aa626e7f-57dd-4084-9486-3eeb921333c0.ttf") format("truetype"),url("@{frutiger-path}/8f91ebb0-2b00-4a50-baa9-865a6502384e.svg#8f91ebb0-2b00-4a50-baa9-865a6502384e") format("svg");
}
@font-face{
font-family:"FrutigerLTW01-75Black";
src:url("@{frutiger-path}/9b0d3be7-4ec5-4a72-8cc6-89b8b0f0b193.eot?#iefix");
src:url("@{frutiger-path}/9b0d3be7-4ec5-4a72-8cc6-89b8b0f0b193.eot?#iefix") format("eot"),url("@{frutiger-path}/70ad2f15-9e1e-4a42-8fdc-30327ef510c3.woff") format("woff"),url("@{frutiger-path}/ed897ec7-c1b8-4bd4-9d2a-0a7b09661bdc.ttf") format("truetype"),url("@{frutiger-path}/63e3258b-8e81-4153-ba11-ab8f4b2aa7a3.svg#63e3258b-8e81-4153-ba11-ab8f4b2aa7a3") format("svg");
}

@font-face {
  font-family: "Frutiger";
  src: url("@{frutiger-path}/af97a512-f55a-4f61-b2ce-659c883930fc.eot?#iefix");
  src: url("@{frutiger-path}/af97a512-f55a-4f61-b2ce-659c883930fc.eot?#iefix") format("eot"), url("@{frutiger-path}/3fbbd6b1-cfa7-4ff0-97ea-af1b2c489f15.woff") format("woff"), url("@{frutiger-path}/076311f8-a01b-4389-a5eb-334a62e62d45.ttf") format("truetype"), url("@{frutiger-path}/c59a36ab-4ef8-4ee1-a2f8-48aa79d4f877.svg#c59a36ab-4ef8-4ee1-a2f8-48aa79d4f877") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Frutiger";
  src: url("@{frutiger-path}/fbd7c0fb-f004-4e83-81d2-1d400413a873.eot?#iefix");
  src: url("@{frutiger-path}/fbd7c0fb-f004-4e83-81d2-1d400413a873.eot?#iefix") format("eot"), url("@{frutiger-path}/9aa32a81-1124-4c43-b3db-15bfb1f7aed2.woff") format("woff"), url("@{frutiger-path}/6faffbf4-f8e8-4817-b24b-a390e166be7e.ttf") format("truetype"), url("@{frutiger-path}/fc09de64-de25-425e-90dc-a0cae29b02c4.svg#fc09de64-de25-425e-90dc-a0cae29b02c4") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Frutiger";
  src: url("@{frutiger-path}/fcbab1a3-9659-4b39-8479-cc1f50d18f55.eot?#iefix");
  src: url("@{frutiger-path}/fcbab1a3-9659-4b39-8479-cc1f50d18f55.eot?#iefix") format("eot"), url("@{frutiger-path}/e2ade8a9-bd48-45e7-95e6-9dc917ba4f74.woff") format("woff"), url("@{frutiger-path}/19fe9614-8825-4e7d-b8c3-230d2561e0e2.ttf") format("truetype"), url("@{frutiger-path}/c2f8e3aa-1c1a-49ee-9ec7-30f61a5e96d3.svg#c2f8e3aa-1c1a-49ee-9ec7-30f61a5e96d3") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Frutiger";
  src: url("@{frutiger-path}/7d3671fc-8c26-459c-98e1-997c4f6c8c93.eot?#iefix");
  src: url("@{frutiger-path}/7d3671fc-8c26-459c-98e1-997c4f6c8c93.eot?#iefix") format("eot"), url("@{frutiger-path}/2a004a53-ac5c-43b3-9eeb-9f74ae4c1609.woff") format("woff"), url("@{frutiger-path}/74d53f3b-1683-4d5a-a556-e13f6553cdf0.ttf") format("truetype"), url("@{frutiger-path}/3f5a5b87-e71e-4544-be0c-da4daa132710.svg#3f5a5b87-e71e-4544-be0c-da4daa132710") format("svg");
  font-weight: 200;
  font-style: normal; }
