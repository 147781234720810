.sll_doc__classification {
  padding: 5px;
  border:1px solid;
  
  &-1, &-A {
      background:green;
      color:white;
  }
  &-2, &-C, &-CC {
      background:yellow;
  }
  &-3 {
      background:red;
  }
  &-B {
      background:white;
  }

}

.sll-doc-search-result {
    position: relative;
    li {
        padding:10px;
    }
    li:nth-child(even) {
        background: #f1f0ee;
    }
    .sll-doc-search-hit-target {
        float:right;
    }
}

.sll-highlight {
    background:yellow;
}

