.sll-news-archive {

    .sv-row {
        &:first-child {
            background-color: #e1ddda;
            margin-right: auto;
        }

        h2, p {
            margin-left: 1em;
        }
    }

    .sol-news-selector {
        margin-left: 16px;
        @media @sm {
            margin-top: 0;
            margin-left: 15px;
        }
        &__archive-toggle, &__date, &__text {
            width: 180px;
            height: 40px;
            font-size: 15px;
            font-weight: normal;
            color: #222222;
            padding-left: 16px;
            margin-left: 10px;

            @media @sm {
                margin-bottom: 10px;
                width: 92%;
            }            
        }

        option {

        }

    }

    .sll-movie-load-more {
        //display: block !important;
    }

    .sll-news-list {
        list-style: none;
        padding-left: 0;
        &-item {
            background-color: white;
            padding: 1.5em;
            padding-bottom: 0;
            padding-top: 0.5em;
            display: inline-block;
            margin-right: 0.9em;
            margin-bottom: 1em;
            height: 9em;
            width: 19em;
            font-size: 24px;
            //width: 31.7%;
            @media (max-width:1426px) and (min-width:992px) { 

            }

            @media @sm {
                width: 100%;
                height: 100%;
            }
            overflow: hidden;
            &:nth-child(odd) {
                border-top: solid 8px#042e59;
            }
            &:nth-child(even) {
                border-top: solid 8px #e99023;
            }
            &:nth-child(3n) {                
                @media (min-width: @lg-min) {
                    margin-right: 0;
                }
                @media (max-width:1426px) and (min-width:992px) { 
                    margin-right: 0.9em;
                }
            }

            &:nth-child(4n) {
                
            }

            &__date {
                font-size: 18px;
                font-weight: bold;
            }

            &__header {
                font-size: 22px;
                font-weight: 500;
                line-height: 1;
                margin-top: 5px;
                margin-bottom: 5px;
            }

            &__summary {
                font-style: italic;
                font-size: 16px;
                line-height: 1;
                padding-top: 10px;
                margin-bottom: -5px;
            }

            &__link {
                font-size: 16px;
                &:hover {
                    opacity: 0.7;
                }
                &::before {
                    content: '\f105';
                    font-family: FontAwesome;
                    font-weight: bold;
                    padding-right: 5px;
                } 
            }
        }
    }
}