thead {
  th {
      .tablesorter-header-inner {
          display: inline-block;
      }
  }
}

#sll_substance_table {
    th {
        background-color: #034ea2;

        &.tablesorter-header::after {
            font-family:"fontawesome";
            padding-left: 5px;
            color:white;
        }
        &.tablesorter-headerAsc::after { 
            content: "\f107";
        }
        &.tablesorter-headerDesc::after { 
            content: "\f106";
        }
    }
}
