* {
  box-sizing: border-box;
}

.sll-flex-row {
  > .sv-layout { // .sv-layout-x
    > * {
      height:100%;
      box-sizing: border-box;
      background-color: white; // NOTE: är det så överallt?
    }

    .sv-text-portlet-content {
      //padding: 20px; 
    }
  }
}

.sll-content-padding {
  padding: 20px;
}

.sv-row {
  padding-top:20px;
  padding-bottom: 20px;
}

strong {
  font-weight: bold;
}

.sol-cookie-message {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 270px;
  border-top-left-radius: 3px;
  background-color: #3d3d3d;
  color: #fff;
  padding: 8px 10px 8px 14px;
  font-size: 13px;

  a {
    color: #fff;
    text-decoration: underline;
  }

  .sol-cookie-ok {
    display: inline-block;
    padding: 0 7px;
    border-radius: 2px;
    background-color: #fff;
    color: #58aac8;
    font-size: 10px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    float: right;
    margin-top: 3px;
  }
}