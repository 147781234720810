/* file: diagnosis.less */
.sll_diagnosis {
  padding-left:0;
  * {
      //font-size:15px;
  }
  &__item {
      width: 100%;
      text-align: left;
      padding: 10px;
      &--collapsed::before, &--expanded::before {
          font-family: 'courier new';
          position: relative;
          padding-right: 2px;
          font-weight: bold;
          float: right;
          cursor: pointer;
      }
  }
  &__item--collapsed::before {
      content:"+";
      font-size: 1.15em;
      //top:-2px;
  }
  &__item--expanded::before {
      content:"-";
      font-size: 1.15em;
      //top:2px;
  }
  button {
      cursor: pointer;
      background-color: transparent;
      border:0;
  }
  li {
      list-style: none;
  }
  & > li:nth-child(odd) {
      .sll_diagnosis__item--button {
          background-color: rgba(167, 157, 150, 0.35);           
      }
  }
}
