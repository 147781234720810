* {
    box-sizing: border-box;
}
.sol-latest-movies-list, .sll-news--list {
    > ul {
        list-style: none;
        padding: 0;
        display: inline-flex;
        width: 100%;
    }
    .sv-channel-item {
        //flex: 0 1 32.3%;
        width: calc(100% * (1/3) - 10px - 1px);
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 16px;
        &:nth-child(3n) {
            //margin-left: auto;
            margin-right: auto;
            @media @sm {
                margin-right: 8px !important;
            } 
        }

        @media @sm {
            width: 100%;
            margin-right: 8px;
        }

        h1, h2, h3 , h4, h5, h6 {
            margin: 0;
        }
        .sol-movie-list-item {


        &__img { 
            height: 230px;
            width: 100%;
            background-position: center;
            background-size: cover;
        }
        &__text {
            padding: 20px 10px;
            background-color: #fff;
            height: 180px;
            display: flex;
            flex-direction: column;
            h3 {
                font-weight: lighter;
                font-size: 18pt;
                line-height: 28pt;
                font-weight: bold;
            }
            h2 {
                font-weight: lighter;
                font-size: 30pt;
                line-height: 34pt;
            }
        }
        }
        .sol-read-more-link {
            margin-top: auto; 
            font-size: 18px;
            line-height: 28px; 
            color: #034ea2;
            position: relative;
            &::before {
                line-height: 1;
                font-family: FontAwesome;
                content: "\f054";
                font-size: 1em;
                line-height: 1.7;
                margin-right: 5px;
                display: inline-block;
                text-decoration: none;
            }
        }


    }
}

.sll-news--list {
    > ul {
        flex-wrap: wrap;
    }
}

.sll-movie-load-more {
    background-color: transparent;
    border: none;
    font-weight: bold;
    position: relative;
    left: 50%;
    padding-bottom: 4em;

    .circle-icon {
        background-color: #eb9100;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        margin-left: -0.5em;
        margin-top: 0.5em;
    }

    .fa-angle-down {
        color: white;
        font-size: 2.5em;
        left: 50%;
    }
}

.sll-movie-modal {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    top: 10% !important;
    width: auto;
    border: none;
    box-shadow: none;
    padding-left: 0 !important;
    
    .modal-dialog {
        max-width: 640px;
        //margin-left: -12.3em;
    }

}

.modal-backdrop {
    background-color: black !important;
    //opacity: .2 !important;
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .8 !important;
}

