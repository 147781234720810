.sll-search-page {
    background-color: rgb(241, 240, 238);

    .sv-search-result {
        li {
            padding-top: 1.5em !important;
            padding-bottom: 1.5em !important;
            border-bottom: solid 1px #000000;
            border-radius: 0; 

            .pathText {
                font-size: 18px;
                a {
                    &:first-child {
                        display: none;
                    }

                    & + a {
                        &:not(:nth-child(2)):before {
                            content: ' / ';
                        }
                    }
                }
            }

            &:first-child {
                border-top: solid 1px #000000;
            }

            .typeOf {
                font-style: italic;
            }
        }

        .sv-search-hit {
            a {
                &:last-child {
                    color: #034ea2;
                }
                
            }
        }

    }

    .sv-facetedsearch-portlet {
        background-color: white;
        border-top: solid 10px #1daeec;
        padding: 2em;
        //min-height: 1742px;
    }

    .sol-decoration-text-box--blue .sv-layout { //Facetterna på söksidan
        //height: 240px;
    }

    .sv-search-facets {
        display: none;
    }

    .sv-decoration-content {
        .sv-search-facets {
            display: block;
        }
    }

    .searchHit {
        font-size: 35px;
    }
    
    /* Sökrutan */
    form {
        display: none;
    }

    .sol-news-list-container, .sol-education-list-container {
        ol {
            margin-top: 0;
        }
        li {
            border-top: none !important;
            border: none;
            padding-top: 15px !important;
            padding-bottom: 0 !important;
        }
    }
}