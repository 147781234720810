/* CSS för menyn ligger i "menu.less" */

.sll-header {

    background-color: #002d5a;
    *, .font-normal {
      color: white;
    }

    .sv-row {
        padding-bottom: 0;
        @media @sm {
            margin-left: -8.5px;
            margin-right: -8.5px;
        }
    }

    .sv-html-portlet {
        width: 49%;
        @media @sm {
           width: 91%;
        }

        @media only screen and (device-width : 360px)  and (device-height : 740px) {
            //width: 81%;
        }

        .sol-icon__januslogo {
            background-repeat: no-repeat;
            height: 50px;
            width: 100%;
            &::after {
                content: '';
                background-image: url('@{sol-icons-path}/Janusinfo_Vit.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: left;
                height: 75px;
                width: 100%;
                width: 310px;
                float: left;
                margin-top: -15px;
                @media screen and (max-width: 767px) and (min-width: 327px) {
                    width: 240px;
                    margin-top: -10px;
                }
                @media screen and (max-width: 327px) and (min-width: 287px) {
                    width: 200px;
                    margin-top: -3px;
                }
                @media screen and (max-width: 287px) and (min-width: 267px) {
                    width: 170px;
                    margin-top: -1px;
                }

                @media screen and (max-width: 267px) and (min-width: 200px) {
                    width: 150px;
                    margin-top: -1px;
                }
            }

            &Text {
                &::after {
                    background-image: url('@{sol-icons-path}/JanusInfoBaraText.svg');
                }
            }
        }
    }


    /* Sökrutan */
    input[type="text"] {
        padding-left: 1em;
        color: black;
        width: 99.25%;
        border-radius: 50px;
        margin-top: 2em;
        height: 2em;
        border: none;
        outline: none;
        @media screen and (max-width: 1100px) and (min-width: 767px) {
            width: 99%;
        }

        @media screen and (max-width: 767px) and (min-width: 720px) {
            width: 98.5%;
        }

        @media screen and (max-width: 720px) and (min-width: 677px) {
            width: 98.4%;
        }

        @media screen and (max-width: 677px) and (min-width: 650px) {
            width: 98.2%;
        }

        @media screen and (max-width: 650px) and (min-width: 604px) {
            width: 98.2%;
        }

        @media screen and (max-width: 604px) and (min-width: 595px) {
            width: 98%;
        }

        @media screen and (max-width: 595px) and (min-width: 540px) {
            width: 97.8%;
        }

        @media screen and (max-width: 540px) and (min-width: 498px) {
            width: 97.8%;
        }

        @media screen and (max-width: 498px) and (min-width: 480px) {
            width: 97.4%;
        }

        @media screen and (max-width: 480px) and (min-width: 430px) {
            width: 97.4%;
        }

        @media screen and (max-width: 430px) and (min-width: 396px) {
            width: 97.2%;
        }

        @media screen and (max-width: 396px) and (min-width: 370px) {
            width: 97%;
        }

        @media screen and (max-width: 370px) and (min-width: 349px) {
            width: 96.8%;
        }

        @media screen and (max-width: 349px) and (min-width: 329px) {
            width: 96.6%;
        }

        @media screen and (max-width: 329px) and (min-width: 312px) {
            width: 96.4%;
        }

        @media screen and (max-width: 312px) and (min-width: 297px) {
            width: 96.2%;
        }

        @media screen and (max-width: 297px) {
            width: 96%;
        }

        &:focus {
          outline-width: 1px;
          outline-style: auto;
          outline-color: -webkit-focus-ring-color;
        }
    }

    /* Sök-knappen */
    button[type="submit"] {
        background-color: #002d5a;
        border-radius: 50px !important;
        border-color: #002d5a;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-top-style: solid;
        display: block;
        width: 1.8em;
        height: 1.8em;
        outline: none;
        float: right;
        margin-top: -1.9em;
        position: relative;
        margin-right: 0.76em;
        color: #002d5a;
        cursor: pointer;

        svg {
            margin-top: 2px;
            margin-left: -3px;
        }

        &:focus {
          outline-width: 2px;
          outline-style: auto;
          outline-color: #cf7b14;
        }
    }

    /* Label-texten i sökrutan */
    .sv-vamiddle {
        &::placeholder {
            color: black;
        }
    }

    .sv-searchform-portlet {
        margin-top: -1.5em;
        font-size: initial;
    }

    &__bottom {
      display: flex;
      align-items: flex-end;
      padding-bottom: 12px;
      padding-top: 1.5em;
      position: relative;
      @media @sm {
          padding-top: 0.5em;
      }

      & > .sv-image-portlet {
        margin-right: auto;
      }

      // Kommersiellt obunden läkemedelsinformation
      & .sv-text-portlet {

        /* Mobil-läge */
        @media @sm {
            display: none;
        }

        & p.font-normal {
            margin-top:0;
            margin-bottom: -5px;
            font-size:16px;
            //width: 601px;
            @media screen and (max-width: 1350px) {
                max-width: 577px;
            }
        }
      }

      .sv-script-portlet {
        position: absolute;
        right: 0;
        @media only screen and (min-width: 767px) {
            margin-left: 4em;
        }
      }
    }

}
