.sll-doc__env {
  &-pbt-item {
    padding-right: 10px;
  }

  &-pbt-index-value {
		background:#1275b9;
		color:white;
		border-radius: 50%;
    padding: 3px;
    display: inline-block;
    width:25px;
    height:25px;
    text-align: center;
  }
  &-pbt-heading {
    font-weight:bold;
  }
}
