.sll-start-page-icon-row {
    .sol-top-shortcut {
        display: flex;
        flex-wrap: nowrap;
        @media (max-width: @lg-min) {
            flex-wrap: wrap;
          }

        &__item  {
            background-color: @white;
            height: 8.5em;
            padding-bottom: 30px;
            text-align: center;
            font-size: @font-size-base;
            @media @sm {
                font-size: 14px;
            }

            @media screen and (max-width: 767px) {
                height: 150px;
            } 

            font-weight:500;
            font-family: @header-font;     
            text-decoration: none;
            position: relative;
            width: 100%;
            border-bottom: 1px solid @shortcut-item-border-bottom;
            border-right: 1px solid @shortcut-item-border-right;
            @media (max-width: @lg-min) {
                width: 33.3%;
            }

            .sol-hover-text {
                visibility: hidden;
            }  

            &:hover {
                background-color: lightgrey;

                span {
                    display: none;
                    @media only screen and (max-width: 563px) {
                        display: block;
                    }
                }

                .sol-hover-text {
                    visibility: visible;
                    margin-top: -0.5em;
                    font-size: 15px;
                    color: black;

                    @media only screen and (max-width: 1070px) and (min-width: 768px) { 
                        font-size: 13px;
                    }

                    @media only screen and (max-width: 768px) and (min-width: 563px) { 
                        font-size: 11px;
                    }                    

                    @media only screen and (max-width: 563px) { 
                        visibility: hidden;                
                    }



                }
            }

            .sol-icon {
                font-size: 90px;
                color: @shortcut-icon-color;
                display: block;
                margin-bottom: 10px;
                max-height: 70px;
                &::after {
                    height: 95px;
                    width: 75px;
                }
            }

            &__name {
                text-decoration: none;
                text-align: center;
            }
        }
    }
    
     .second-level{
        padding-bottom: 30px;
        & .sol-top-shortcut__item  {
            background-color: @shortcut-second-level-bg;
            border-right: 1px solid @shortcut-second-level-border;

            &:last-child {
                border-right: none;
            }

            @media (max-width: @lg-min) {
                border-bottom: 1px solid @shortcut-second-level-border;

                &:nth-child(3n) {
                    border-right: none; 
                 }
            }

            &:hover {
                background-color: lightgray;
            }
        }
     }

     p.font-normal
     {
        margin-top: 30px;
        background-color: @shortcut-heading-bg;
        color: @white;
        font-size: @font-size-base;
        font-weight: 500;
        font-family: @header-font;     
        padding: 3px 0px 3px 20px;
        margin-bottom: 0;
     }
}

