.icon-size(@height;
@width: @height) {
  max-height: @height;
  &::after {
    height: @height;
    width: @width;
  }
  &.sol-icon-edge {
    height: @height;
    width: @width;
    &::after {
      transform: scale(0.5);
      height: @height * 2;
      width: @width * 2;
      margin-top: -(@height / 2);
      margin-left: -(@width / 2);
    }
  }
}
.sol-icon {
  display: inline-block;
  &::after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
    height: 15px;
    width: 15px;
  }
}

.sol-icon__row1col1 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row1col1.svg');
  }
}
.sol-icon__row1col2 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row1col2.svg');
  }
}
.sol-icon__row1col3 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row1col3.svg');
  }
}
.sol-icon__row1col4 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row1col4.svg');
  }
}
.sol-icon__row1col5 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row1col5.svg');
  }
}
.sol-icon__row1col6 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row1col6.svg');
  }
}
.sol-icon__row2col1 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row2col1.svg');
  }
}
.sol-icon__row2col2 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row2col2.svg');
  }
}
.sol-icon__row2col3 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row2col3.svg');
  }
}
.sol-icon__row2col4 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row2col4.svg');
  }
}
.sol-icon__row2col5 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row2col5.svg');
  }
}
.sol-icon__row2col6 {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/row2col6.svg');
  }
}

.sol-icon__arrow {
  .icon-size(100px, 50px);
  &::after {
      background-image: url('@{sol-icons-path}/arrows.svg');
  }
}

.sol-icon__owl {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/owl.svg');
    }
}

.sol-icon__gender {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/gender.svg');
      }
}

.sol-icon__environment {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/environment.svg');
      }
}

.sol-icon__national {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/national.svg');
      }
}

.sol-icon__kidney {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/kidney.svg');
      }
}

.sol-icon__head {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/head.svg');
      }
}

.sol-icon__graphs {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/graphs.svg');
      }
}

.sol-icon__strama {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/strama.svg');
      }
}

.sol-icon__heart {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/heart.svg');
      }
}

.sol-icon__breast-feeding {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/breast-feeding.svg');
      }
}

.sol-icon__baby {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/baby.svg');
      }
}

.sol-icon__interactions {
    .icon-size(100px, 50px);
    &::after {
        background-image: url('@{sol-icons-path}/interactions.svg');
      }
}



.sol-icon__arrow {
  &::after {
    background-image: url('@{sol-icons-path}/arrow.svg');
  }
}
.sol-icon__arrow--black {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--black.svg');
  }
}
.sol-icon__arrow--blue {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--blue.svg');
  }
}
.sol-icon__arrow--white {
  &::after {
    background-image: url('@{sol-icons-path}/arrow--white.svg');
  }
}
.sol-icon__books {
  &::after {
    background-image: url('@{sol-icons-path}/books.svg');
  }
}
.sol-icon__briefcase {
  &::after {
    background-image: url('@{sol-icons-path}/briefcase.svg');
  }
}
.sol-icon__chat {
  &::after {
    background-image: url('@{sol-icons-path}/chat.svg');
  }
}
.sol-icon__clock {
  &::after {
    background-image: url('@{sol-icons-path}/clock.svg');
  }
}
.sol-icon__clock-small {
  &::after {
    background-image: url('@{sol-icons-path}/clock-small.svg');
  }
}
.sol-icon__clock-small--white {
  &::after {
    background-image: url('@{sol-icons-path}/clock-small--white.svg');
  }
}
.sol-icon__commenting {
  &::after {
    background-image: url('@{sol-icons-path}/commenting.svg');
  }
}
.sol-icon__compare {
  &::after {
    background-image: url('@{sol-icons-path}/compare.svg');
  }
}
.sol-icon__computer {
  &::after {
    background-image: url('@{sol-icons-path}/computer.svg');
  }
}
.sol-icon__e-services {
  &::after {
    background-image: url('@{sol-icons-path}/e-services.svg');
  }
}
.sol-icon__english {
  &::after {
    background-image: url('@{sol-icons-path}/english.svg');
  }
}
.sol-icon__eye {
  &::after {
    background-image: url('@{sol-icons-path}/eye.svg');
  }
}
.sol-icon__eye--light {
  &::after {
    background-image: url('@{sol-icons-path}/eye--light.svg');
  }
}
.sol-icon__fax {
  &::after {
    background-image: url('@{sol-icons-path}/fax.svg');
  }
}
.sol-icon__globe {
  &::after {
    background-image: url('@{sol-icons-path}/globe.svg');
  }
}

.sol-icon__globe--black {
  &::after {
    background-image: url('@{sol-icons-path}/globe--black.svg');
  }
}

.sol-icon__globe--white {
  &::after {
    background-image: url('@{sol-icons-path}/globe-white.svg');
  }
}
.sol-icon__info {
  &::after {
    background-image: url('@{sol-icons-path}/info.svg');
  }
}
.sol-icon__info-plain {
  &::after {
    background-image: url('@{sol-icons-path}/info-plain.svg');
  }
}
.sol-icon__legible {
  &::after {
    background-image: url('@{sol-icons-path}/legible.svg');
  }
}

.sol-icon__legible--black {
  &::after {
    background-image: url('@{sol-icons-path}/legible--black.svg');
  }
}

.sol-icon__legible--white {
  &::after {
    background-image: url('@{sol-icons-path}/legible-white.svg');
  }
}
.sol-icon__link {
  &::after {
    background-image: url('@{sol-icons-path}/link.svg');
  }
}
.sol-icon__listen {
  &::after {
    background-image: url('@{sol-icons-path}/listen.svg');
  }
}
.sol-icon__listen--white {
  &::after {
    background-image: url('@{sol-icons-path}/listen-white.svg');
  }
}
.sol-icon__lock {
  &::after {
    background-image: url('@{sol-icons-path}/lock.svg');
  }
}

.sol-icon__lock--black {
  &::after {
    background-image: url('@{sol-icons-path}/lock--black.svg');
  }
}

.sol-icon__lock--white {
  &::after {
    background-image: url('@{sol-icons-path}/lock-white.svg');
  }
}
.sol-icon__mail {
  &::after {
    background-image: url('@{sol-icons-path}/mail-blue.svg');
  }
}
.sol-icon__mail-white {
  &::after {
    background-image: url('@{sol-icons-path}/mail.svg');
  }
}
.sol-icon__phone {
  &::after {
    background-image: url('@{sol-icons-path}/phone.svg');
  }
}

.sol-icon__phone--black {
  &::after {
    background-image: url('@{sol-icons-path}/phone--black.svg');
  }
}

.sol-icon__phone--white {
  &::after {
    background-image: url('@{sol-icons-path}/phone-white.svg');
  }
}
.sol-icon__print {
  &::after {
    background-image: url('@{sol-icons-path}/print.svg');
  }
}
.sol-icon__search {
  &::after {
    background-image: url('@{sol-icons-path}/search.svg');
  }
}
.sol-icon__search--green {
  &::after {
    background-image: url('@{sol-icons-path}/search-green.svg');
  }
}
.sol-icon__search--pink {
  &::after {
    background-image: url('@{sol-icons-path}/search-pink.svg');
  }
}
.sol-icon__share {
  &::after {
    background-image: url('@{sol-icons-path}/share.svg');
  }
}
.sol-icon__signlanguage {
  &::after {
    background-image: url('@{sol-icons-path}/teckensprak.svg');
  }
}
.sol-icon__tickets {
  &::after {
    background-image: url('@{sol-icons-path}/tickets.svg');
  }
}
.sol-icon__tipsa {
  &::after {
    background-image: url('@{sol-icons-path}/tipsa.svg');
  }
}
.sol-icon__warning {
  &::after {
    background-image: url('@{sol-icons-path}/warning.svg');
  }
}
.sol-icon__work {
  &::after {
    background-image: url('@{sol-icons-path}/work.svg');
  }
}
.sol-icon__x {
  &::after {
    background-image: url('@{sol-icons-path}/x.svg');
  }
}
.sol-icon__gear {
  &::after {
    background-image: url('@{sol-icons-path}/gear.svg');
  }
}
