.sv-decoration-content {
  padding:20px;
  .sv-text-portlet-content > p {
    margin: 0px;
  }
  img {
    //max-width:100%;
    //object-fit: cover;
  }
}

.sll-decoration-faktabox {
    //margin-bottom: 15px;
    p {
        //padding: 1em;
    }

    &--bg-lightgray {
        //background-color: #e0ded9;
    }

    &--bg-yellow {
        //background-color: #ffd400;
    }

    &--bg-blue {
        //background-color: #00aeef;
    }
}

.sll-puff-decoration {
    &--grey, &--blue, &--white {
        padding: 20px;

        .sv-row {
            padding-top: 0;
        }

        h1.font-heading-1 {
            margin-bottom: 8px;
        }
        h1.font-heading-1, h2.font-heading-2, h2.font-heading-3, h4.font-heading-4 {
            margin-top: 0;
        }
        p.font-normal {
            margin-bottom: 6px;
        }
        .font-normal a {
            margin-bottom: 6px;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &--grey {
        background-color: #e0ded9 !important;
    }
    &--blue {
        background-color: rgb(224, 234, 239) !important;
    }
    &--white {
        background-color: #fff !important;
    }

}
