.sll-decoration-button--blue {
    background-color: #002d5a;
    color: #fff;
    .font-normal a {
        color: #fff;
        text-decoration: none;
        position: relative;
        &:hover {
            color: #dbdbdb;
        }
        &::after {
            position: absolute;
            right: -1em;
            line-height: 1; 
            font-family: FontAwesome;
            content: "\f054";
            font-size: 1em;
            line-height: 1.7;
        }
    }
}   
  

.btn-decoration {  
    padding: 20px; 
    color: #fff;
    .font-normal  {
        color: #fff;
        margin-bottom: 0;
    }
    .font-normal a {
        color: #fff;
        text-decoration: none;
        position: relative;
        &:hover {
            color: #dbdbdb;
        }
        &::after {
            position: absolute;
            right: -1em;
            line-height: 1;
            font-family: FontAwesome;
            content: "\f054";
            font-size: 1em;
            line-height: 1.7;
        } 
    }
}

.sll-decoration-button {
    &--orange, &--dark-gray {
        .btn-decoration;
    }
    &--orange {
        background-color: #eb9100;
    }
    &--dark-gray {
        background-color: rgb(104, 92, 83);
    }
}