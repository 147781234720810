/** file: breadcrumbs.less */
.sol-breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  margin-top: 10px;
  font-size: initial;

  @media @sm {
    margin-top: 0px;
  }
  li {
    float: left;
  }
} 
