.sll-wiseList {
  background-color: white;

  p, a {
    //font-size: 18px !important;
  }

  #breadcrumbsContainer {
    display: none;
  }

  .sol-breadcrumbs {
    padding-left: 10px;
  }

  #column-one {
    @media only screen and (min-width: 1260px) {
      position: absolute;
      margin-left: -120px;
      margin-top: 20px;
      width: 341px;
    } 

    @media only screen and (max-width: 990px) {
      margin-left: 20px;
    }

    margin-top: 15px;
    border-top: 8px solid #042e59;
    background-color: #f1f0ee;
    .content {
      ul {
        li.hidden-phone {
          //border-bottom: 1px solid black;
          a {
            //color: black;
            font-size: 14px; //29px;
            //font-weight: bold;
          }          
        }

        li {
          padding-top: 10px;
          padding-bottom: 10px;
          a {
            font-size: 15px; //18px !important;
            //color: #034ea2;
            //font-weight: 300;
            @media only screen and (max-width: 1260px) {
              //font-size: 22px !important;
            }
          }
        }
      }
    }
  }

  #column-two-three {
    @media only screen and (min-width: 1260px) {
      margin-left: 245px;
      margin-top: 20px;
    }
    margin-top: 15px;
    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 795px) and (min-width: 768px) {
      width: 445px;
    }
  }

  #container {
    background-color: white;
  }

  #main {
    @media only screen and (min-width: 767px) {
      min-height: 1370px;
    }
    margin-bottom: 0;
  }

  #column-four {
    @media only screen and (min-width: 1260px) {
      position: absolute;
      margin-left: 740px;
      margin-top: 20px;
    }
    margin-top: 15px;

    h2 {
      //font-size: 20px;
    }    

    > ul {
      font-size: 12px;
    }
  }

  .container-orange-top {
    @media only screen and (min-width: 1260px) {
      width: 341px;
    }

    @media only screen and (max-width: 962px) {
      border-top: 20px solid white;
    }

    .header {
      background: #f1f0ee !important;
      border-top: 8px solid #042e59 !important;
      border-radius: 0 !important;
      
      h2 {
        color: black;
        margin-top: 7px;
        //font-size: 29px;
        //font-weight: bold;
        @media only screen and (max-width: 1260px) {
          //font-size: 22px;
        }
      }
    }

    .content {
      background: #f1f0ee;
      border-radius: 0;
      border: none;

      ul {
        li {
          a {
            //color: #126998;
            //font-weight: 300;
          }
        }
      }
    }
  }

  .container-round-left {
    li {
      @media only screen and (max-width: 767px) {
        padding-bottom: 10px !important;
        padding-top: 10px;
        &:not(:last-child) {
          border-bottom: 1px solid lightgray;
        }        
      }      
    }
  }

  .IndikationAnchorLinks {
    //font-size: 18px;
  }

  #header {
    display: none;
  }
  
  #footer_container {
    display: none !important;
  }

  .footer-mobile-container {
    padding-top: 15px;

    .footer-mobile-logo-klokalistan {
      display: none !important;
    }

    .footer-mobile-logo-janus {
      display: none !important;
    }
  }

  .static-footer-container, .static-header{
    display: none;
  }
  
}
