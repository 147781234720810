/* file:news_listing.less */
.sll-news {
  padding-bottom: 2em;

  .sll-article {
      &-listing {
          width:100%;
      }

      &__item {
        .sll-content-padding {
          padding-top: 5px;
        }
      }      

      &__title {
        //font-weight: normal;
        margin:0;
        margin-top: 10px;
        font-size: 30pt;
        line-height: 34pt; 
        a {
          color: #000000;
        }
      }

      &__category {
        margin-top: 0;
        margin-bottom: 5px !important;
        margin-top: 5px !important;
        //font-size: 18pt;
        //line-height: 28pt;
        font-weight: bold;
      }

      &__img {
        img {
          min-width:100%;
          max-width:100%;
        }
      }

      &__preamble {
          font-size: 16px;
          line-height: 1em;
      }

      &__link {
        margin-top: 5px;
        a {
          //font-size: 18pt;
          margin-left: 0.2em;
          &:hover {
            opacity: 0.7;
            text-decoration: none;
          }
          &::before {
            content: '\f105';
            font-family: FontAwesome;
            font-weight: bold;
            padding-right: 5px;
          }
        }
      }
  }

  .font-heading-2 {
    font-weight: normal;
  }
  
  li {
      width: 31.8%;
      margin-right: 2.5em;
      background-color: white;
      &:nth-child(3) {
          margin-right: 0 !important;
      }
      /* Mobil */
      @media @sm {
          width: 96%;
          margin-right: 0;
          margin-bottom: 1em;
          margin-left: 8px;
      }
  }

  ul {
      display: inline-flex;
      width: 100%;
      /* Mobil */
      @media @sm {
          display: block;
      }
  }
}