.sll-doc {
	&__heading {
		position: relative;
		padding:5px;
		h1, h2 {
			margin: 0;
		}

		h1 {
			margin-bottom: 15px;
		}
	}
	&__heading-label {
		position: absolute;
		right:50px;
		top:15px;

		@media @sm {
			position: initial;
			display: inline-block;
			margin-bottom: 10px;
		}
	}
	&__heading-value {
		background: white;
		position: absolute;
		right:15px;
		top:10px;
		border:solid 2px;
		padding: 5px;
		width:30px;
		height:30px;
		text-align:center;
		
		@media @sm {
			position: initial;
		}

		&-1, &-A {
			background:green;
		}
		&-2, &-C, &-CC {
				background:yellow;
		}
		&-3 {
				background:red;
		}
		&-B {
			background:white;
		}
	}

	&__references {
		margin-top: 20px;
	}

	&__heading-pbt {
		display:flex;
		position:absolute;
		right:10px;
		top: -22px;

		@media @sm {
			position: initial;
		}
		
	}

	&__pbt-item {
		padding:5px;
		span:nth-child(1) {
			font-weight:bold;
		}
		span:nth-child(2) {
			display:block;
			text-align: center;
			padding-top:5px;
		}
	}

	&__pbt-index-value {
		background:#1275b9;
		color:white;
		border-radius: 50%;
		width:25px;
		height:25px;
	}
	
	&__reference-link {
		a {
			color:@link-color;
			font-weight:400;
		}
	}
	
	&--font-bold {
		font-weight:bold;
	}

	&__expand-text-button {
		border:none;
		font-size:0.8rem;
		text-decoration: underline;
		&:hover {
				cursor: pointer;
		}
	}

	&__bg {
		&--lightgrey {
			background-color: rgba(237, 237, 237, 0.8);
		}
	}

	&__content {
		padding: 5px;
	}
}

