/** file: article.less */

.sll-article {
    &-wrapper {
        @media @sm {
            margin-top: 10px;
        }

        background-color: rgb(241, 240, 238);
    
        .sv-grid-main {
            background-color: white;
        }
    
        .sll-decoration-top-border {
            margin-bottom: 2em;
        }

        .sv-portlet-image-caption {
            font-size: 18px;
            margin-bottom: 20px;
            margin-top: 10px;
        }
    
        @media (min-width: @lg-min) {
          > .sv-grid-main {
              > .sv-row {
                  > .sv-layout:nth-child(1) {
                    padding-left: 8.3333% !important;
                    padding-right: 8.3333% !important;
                  }
                  > .sv-layout:nth-child(2) {
                    padding-right: 8.3333% !important;
                  }
              }
          }
      }
    }

    &-tableofcontents {

        @media screen and (max-width: 991px) { //Döljer innehållsföteckningen i Pad/mobilläge
            display: none;
        }

        .sv-toc-portlet {
            max-height: 600px;
            overflow-y: auto;
            width: 104%;

            @media screen and (min-height: 1300px) {
                max-height: 900px;
            }

            @media screen and (max-height: 890px) {
                max-height: 500px;
            }

            @media screen and (max-height: 790px) {
                max-height: 400px;
            }
            @media screen and (max-height: 790px) {
                max-height: 300px;
            }

            
        }

        ul {
            li {
                //padding-bottom: 10px;
                //width: 105%;
                padding-top: 8px;
                padding-bottom: 8px;
                @media screen and (max-width: 991px) {
                    width: 102%;
                }
                a {
                        color: #034ea2;
                        font-weight: 300;
                }
                .font-heading {
                    &-2 {
                        font-size: 24px;
                        display: inline-block;
                        margin-top: 15px;
                    }
                    &-3 {
                        font-size: 20px;
                        display: inline-block;
                        margin-top: 15px;
                    }
                }

                &:last-child {
                    padding-bottom: 15px;
                }
                
            }
        }

        .sv-text-portlet-content {
            width: 104%;
            @media screen and (max-width: 991px) {
                width: 102%;
            }
        }

        &-plus { //Varje rubrik
            margin-top: -1.3em !important;
            float: right;
            position: relative;
            //font-size: 24px;
            &::before {
                font-family: "courier";
                content: '+';
                font-weight: 900;
                color: #034ea2;
                font-size: 38px;
            }
            &2 { //Hitta i sidan
                font-weight: 300 !important;
                //font-size: 24px;
                float: right;
                position: relative;
                
                &::before {
                    font-family: "courier";
                    content: '-';
                    font-weight: 900;
                    font-size: 38px;
                    color: #034ea2;
                }
            }    
        }


        &-minus { //Varje rubrik
            margin-top: -1.3em !important;
            float: right;
            position: relative;

            &::before {
                font-family: "courier";
                content: '-';
                font-weight: 900;
                font-size: 38px;
                color: #034ea2;
            }
            &2 { //Hitta i sidan
                font-weight: 300 !important;
                //font-size: 24px;
                float: right;
                position: relative;
                &::before {
                    font-family: "courier";
                    content: '+';
                    font-weight: 900;
                    font-size: 38px;
                    color: #034ea2;
                }
            }      
        }

    }

    &__item {
        .font-heading-2 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}



.sol-article-tableofcontents-script {
    background-color: red;
}