/** file:top_border.less */

.sll-decoration-top-border {
  background-color: white;
  border-top: 8px solid;

  .sv-search-result {
    margin-top: 0;
    li {
      &:first-child {
        padding-top: 0;
      }
    }
  }
  
  h1, h2, h3 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }

  .sv-row {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--blue {
    border-top-color: #042e59;
    &-light {
      border-top-color: #1daeec;
    }
  }
  &--orange {
    border-top-color: #e99023;
  }
  &--red {
    border-top-color: #970e34;
  }
  &--gray {
    border-top-color: #a89e98;
  }

  &--bg-lightgray {
    background-color: rgb(241, 240, 238);
  }
}
