.sol-footer-wrapper {
    background-color: rgb(225, 221, 218); //#e0ded9;
    border-top: 0 !important;

    /* Nyheter, beslutsstöd, behandling m.m */
    .sv-text-portlet-content .font-normal {
        line-height: 28px;
        font-size: 14px;
        margin-top: 1.1em;
        color: #685c53;
    }

    .sv-text-portlet {
        @media only screen and (max-width: 991px) and (min-width: 479px) { //"Dela sidan" får padding
            padding-left: 8.5px;            
        }

        @media only screen and (max-width: 350px) {
            float: none;
        }
        
        a {
            color: #685c53;
            line-height: 28px;
            font-size: 14px;
            &:hover {
                color: #126998;
            }
        }
    }

    .sv-script-portlet {
        left: 0 !important;        
        /* Desktop-läge */
        @media (min-width: @lg-min) {
            margin-top: -2em;
            left: 3% !important;
        }
    }

    strong {
        font-weight: bold;
    }

    /* Delaknappar */
    .sharebutton {
        display: inline-block;
        height: 30px;
        margin: 0 1em .5em 0;
        padding: 0 16px;
        background-color: #ccc;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        line-height: 2;
        text-decoration: none;
        background-size: 18px auto;
        background-position: 12px 50%;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;

        &list {
            list-style-type: none;
            margin: 2em;
            margin-left: -5px;
            margin-bottom: -1em;
            padding: 0;
            &__item {
                display: inline;
            }
            @media only screen and (max-width: 991px) and (min-width: 350px) {
                margin-left: 7px;
                margin-top: 6px;
                float: left;
            }

            @media only screen and (max-width: 350px) {
                margin-left: -2px;
                margin-top: -15px;
            }
        }

        &--facebook {
            background-color: #3b5998;
        }
        &--twitter {
            background-color: #00aced;
        }
        &--linkedin {
            background-color: #007bb6; 
        }
        &--email, &--print {
            background-color: #e1056d; 
        }

        &--icon {
            &-facebook {
                padding: 0 16px 0 32px;
                background-size: 8px auto;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDE3IiB3aWR0aD0iOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTggOS4yaC0yLjd2Ny44aC0zLjN2LTcuOGgtMnYtMi43aDJ2LTIuOGMwLTEuOSAxLjMtMy43IDQuMS0zLjcgMS4yIDAgMS45LjEgMS45LjF2Mi43aC0xLjdjLTEgMC0xLjIuNS0xLjIgMS4zdjIuNGgyLjl2Mi43eiIvPjwvc3ZnPg==');
            }

            &-twitter {
                padding: 0 16px 0 37px;
                background-size: 16px auto;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNyAxMy43IiB3aWR0aD0iMTYiIGhlaWdodD0iMTMiPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xNyAxLjZjLS42LjMtMS4zLjUtMiAuNS43LS40IDEuMy0xLjEgMS41LTEuOS0uNy40LTEuNC43LTIuMi44LS42LS42LTEuNS0xLTIuNS0xLTIgMC0zLjUgMS42LTMuNSAzLjUgMCAuMyAwIC41LjEuOC0yLjktLjItNS41LTEuNi03LjItMy43LS4zLjUtLjUgMS4xLS41IDEuOCAwIDEuMi42IDIuMyAxLjUgMi45LS42IDAtMS4xLS4yLTEuNi0uNCAwIDEuNyAxLjIgMy4xIDIuOCAzLjQtLjIgMC0uNS4xLS44LjEtLjIgMC0uNCAwLS43LS4xLjQgMS40IDEuNyAyLjQgMy4zIDIuNC0xLjIuOS0yLjcgMS41LTQuMyAxLjVoLS44YzEuNSAxIDMuNCAxLjYgNS4zIDEuNiA2LjQgMCA5LjktNS4zIDkuOS05Ljl2LS40Yy42LS42IDEuMi0xLjIgMS43LTEuOXoiLz48L3N2Zz4=');
            }
            &-linkedin {
                padding: 0 16px 0 37px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NDAiIGhlaWdodD0iNjQwIiB2aWV3Qm94PSIwIDAgNjQwIDY0MCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTMyMCAxMi44Yy0xNjkuNjY0IDAtMzA3LjIgMTM3LjUzNi0zMDcuMiAzMDcuMnMxMzcuNTM2IDMwNy4yIDMwNy4yIDMwNy4yIDMwNy4yLTEzNy41MzYgMzA3LjItMzA3LjItMTM3LjUzNi0zMDcuMi0zMDcuMi0zMDcuMnptLTc1LjIgNDM0LjUyOGgtNjIuMjA4di0yMDAuMTkyaDYyLjIwOHYyMDAuMTkyem0tMzEuNDg4LTIyNC43NjhjLTE5LjY0OCAwLTMyLjM1Mi0xMy45Mi0zMi4zNTItMzEuMTM2IDAtMTcuNTY4IDEzLjA4OC0zMS4wNzIgMzMuMTUyLTMxLjA3MnMzMi4zNTIgMTMuNTA0IDMyLjczNiAzMS4wNzJjMCAxNy4yMTYtMTIuNjcyIDMxLjEzNi0zMy41MzYgMzEuMTM2em0yNTguNjg4IDIyNC43NjhoLTYyLjIwOHYtMTEwLjk0NGMwLTI1LjgyNC05LjAyNC00My4zNi0zMS41Mi00My4zNi0xNy4xODQgMC0yNy4zOTIgMTEuODcyLTMxLjkwNCAyMy4yOTYtMS42NjQgNC4wNjQtMi4wOCA5LjgyNC0yLjA4IDE1LjU1MnYxMTUuNDI0aC02Mi4yNHYtMTM2LjMyYzAtMjQuOTkyLS44LTQ1Ljg4OC0xLjYzMi02My44NzJoNTQuMDQ4bDIuODQ4IDI3LjgwOGgxLjI0OGM4LjE5Mi0xMy4wNTYgMjguMjU2LTMyLjMyIDYxLjgyNC0zMi4zMiA0MC45MjggMCA3MS42MTYgMjcuNDI0IDcxLjYxNiA4Ni4zNjh2MTE4LjM2OHoiLz48L3N2Zz4=');
            }
            &-email {
                padding-left: 40px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMiAxNCIgd2lkdGg9IjIyIiBoZWlnaHQ9IjE0Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjAgMGgtMjB2MTRoMjJ2LTE0aC0yem0tMS43IDJsLTcuMyAzLjctNy4zLTMuN2gxNC42em0xLjcgMTBoLTE4di05LjFsOSA0LjcgOS00Ljd2OS4xeiIvPjwvc3ZnPg==');
            }

            &-print {
                background-image: url('@{sol-icons-path}/printer.svg');
            }
        }

        &--hide-text {
            width: 30px;
            height: 30px;
            margin: 0 .125em;
            padding: 0;
            background-position: 50% 50%;
            border-radius: 15px;

            .sharebutton {
                &__text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 1px;
                    width: 1px;
                    overflow: hidden;
                    clip: rect(1px, 1px, 1px, 1px);
                }
            }
        }


    }

    /* Logotyp */
    .sv-html-portlet {

        /* Desktop-läge */
        @media (min-width: @lg-min) {
            float: right;
        }

        .sol-icon__januslogo {
            background-repeat: no-repeat;
            height: 50px;
            width: 50%;
            &::after {
                content: '';
                background-image: url('@{sol-icons-path}/Janusinfo_RGB.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: left;
                height: 75px;
                width: 100%;
                width: 310px;
                float: left;
                margin-top: -15px;
                @media only screen and (max-width: 991px) and (min-width: 310px) {
                    margin-left: -5px;
                    width: 280px;
                }

                @media only screen and (max-width:310px) and (min-width: 280px) {
                    margin-left: -5px;
                    width: 250px;
                }

                @media only screen and (max-width:280px) and (min-width: 250px) {
                    margin-left: -5px;
                    width: 220px;
                }

                @media only screen and (max-width:250px) {
                    margin-left: -5px;
                    width: 190px;
                }
            }
        }
    }
     
    .sv-row {
        @media (min-width: @lg-min) {
            &:nth-child(2), &:nth-child(3) {
                border-top: solid 1px black;
            } 
        }
    }

    .sv-jcrmenu-portlet p, .sv-jcrmenu-portlet li, .sv-jcrmenu-portlet a {
        color: #685c53;
        line-height: 28px;
        font-size: 14px;
    }

} 