.sll_doc_fass_text {
  ul {
      list-style: none;
      margin:0;
      padding:0;
      li {
          white-space: nowrap;
      }
  }

  button {
    border: none;
    font-size: 0.8rem;
    text-decoration: underline;
  }
}

